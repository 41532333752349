import React, { Component } from 'react';
import { Layout } from 'antd';

const {
  Footer,
} = Layout;

class Foot extends Component {
  render() {
    return (
      <Footer style={{ textAlign: 'center' }}>
        Michael Powell ©2019 Created by Michael Powell, using AntD
      </Footer>
    );
  }
}

export default Foot;

import React, { Component } from 'react';
import {PageHeader, Collapse, List, Button} from 'antd';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { ghcolors } from 'react-syntax-highlighter/dist/esm/styles/prism';
import MediaQuery from 'react-responsive';

const Panel = Collapse.Panel;

const code = '// Manhattan Distance Heuristic\n' +
    '  int heuristics(Node node, Node goal) {\n' +
    '\n' +
    '    // Start the score off as 0\n' +
    '    int score = 0;\n' +
    '\n' +
    '    // For every x and y position, we subtract the goal position from ' +
    'the current position, then add it together except for zero\n' +
    '    for(int i = 0; i < node.puzzle.length; i++) {\n' +
    '      for(int j = 0; j < node.puzzle[i].length; j++) {\n' +
    '        if(node.puzzle[i][j] == 0)\n' +
    '          continue;\n' +
    '        score += (Math.abs(i - getCoords(goal, ' +
    'node.puzzle[i][j])[0])) + (Math.abs(j - getCoords(goal, ' +
    'node.puzzle[i][j])[1]));\n' +
    '      }\n' +
    '    }';

const description = (
  <p style={{ paddingLeft: 24 }}>
    This 8 Puzzle Solver project was created with Java using the A* Algorithm
    to solve any puzzle input towards whatever particular goal puzzle is set.
    The particular heuristic used for this particular project
    was the Manhattan Distance.
  </p>
);

const data = [
  'Solve Any Solvable 8 Puzzle',
  'Display Full Solved Path',
  'Manhattan Distance Heuristic',
];
const features = (
  <p style={{ paddingLeft: 24 }}>
    <List
      size="small"
      bordered
      dataSource={data}
      renderItem={item => (<List.Item>{item}</List.Item>)}
    />
  </p>
);

const snippet = (

  <SyntaxHighlighter language='java' style={ghcolors}>
    {code}
  </SyntaxHighlighter>

);

const source = (
  <Button
    icon="download"
    size="small"
    href="https://github.com/mp04499/AStar-8-Puzzle-Solver"
    target="_blank">
    Source
  </Button>
);

class AStar extends Component {

  render() {


    return (
      <div>
        <PageHeader
          onBack={() => this.goBack}
          title="A* 8 Puzzle Solver"
          subTitle="A* Algorithm & Java"
        />

        <MediaQuery query="(min-device-width: 1224px)">
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            style={{ width: '50%' }}
          >
            <Panel header="Description" key="1">
              {description}
            </Panel>
            <Panel header="Features" key="2">
              {features}
            </Panel>
            <Panel header="Code Snippet" key="3">
              {snippet}
            </Panel>
          </Collapse>
          <br />
          {source}
        </MediaQuery>

        <MediaQuery query="(max-device-width: 1224px)">
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            style={{ width: '100%' }}>
            <Panel header="Description" key="1"
            >
              {description}
            </Panel>
            <Panel header="Features" key="2">
              {features}
            </Panel>
            <Panel header="Code Snippet" key="3">
              {snippet}
            </Panel>
          </Collapse>
          <br />
          {source}
        </MediaQuery>
      </div>
    );
  }
}

export default AStar;

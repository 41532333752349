import React, { Component } from 'react';
import {PageHeader, Collapse, List, Button} from 'antd';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { ghcolors } from 'react-syntax-highlighter/dist/esm/styles/prism';
import MediaQuery from 'react-responsive';

const Panel = Collapse.Panel;

const code = 'private void Fire()\n' +
    '\t{\n' +
    '\t\tif (fireTimer < fireRate || currentBullets <= 0 || isReloading)\n' +
    '\t\t\treturn;\n' +
    '\n' +
    '\t\tRaycastHit hit;\n' +
    '\n' +
    '\t\tif (Physics.Raycast(shootPoint.position, ' +
    'shootPoint.transform.forward, out hit, range))\n' +
    '\t\t{\n' +
    '\t\t\tGameObject hitParticleEffect =\n' +
    '\t\t\t\tInstantiate(hitParticles, hit.point, ' +
    'Quaternion.FromToRotation(Vector3.up, hit.normal));\n' +
    '\t\t\thitParticleEffect.transform.SetParent(hit.transform);\n' +
    '\t\t\tGameObject bulletHole =\n' +
    '\t\t\t\tInstantiate(bulletImpact, hit.point, ' +
    'Quaternion.FromToRotation(Vector3.forward, hit.normal));\n' +
    '\t\t\t\n' +
    '\t\t\tDestroy(hitParticleEffect, 1f);\n' +
    '\t\t\tDestroy(bulletHole, 2f);\n' +
    '\n' +
    '\t\t\tif (hit.transform.GetComponent<SkeletonAI>())\n' +
    '\t\t\t{\n' +
    '\t\t\t\thit.transform.GetComponent<SkeletonAI>().ApplyDamage(damage);\n' +
    '\t\t\t}\n' +
    '\t\t}\n' +
    '\n' +
    '\t\tanim.CrossFadeInFixedTime("Fire", 0.01f);\n' +
    '\t\tmuzzleFlash.Play();\n' +
    '\t\tPlayShootSound();\n' +
    '\n' +
    '\t\tcurrentBullets--;\n' +
    '\t\tfireTimer = 0.0f;\n' +
    '\t}';

const description = (
  <p style={{ paddingLeft: 24 }}>
    A First Person Shooter game created in C# through Unity.
    The animations were created using Blender and the Unity
    animation system to link together those animations to
    to create the specific animations you see throughout the
    video game.
  </p>
);

const data = [
  'AI System for NPC Skeletons',
  'Game Management System with Four Waves and Health System',
  'Walking & Reloading Animations',
  'Gun System including Reloading, Fire Rate, and Weapon Swap',
];
const features = (
  <p style={{ paddingLeft: 24 }}>
    <List
      size="small"
      bordered
      dataSource={data}
      renderItem={item => (<List.Item>{item}</List.Item>)}
    />
  </p>
);

const snippet = (

  <SyntaxHighlighter language='csharp' style={ghcolors}>
    {code}
  </SyntaxHighlighter>

);

const source = (
  <Button
    icon="download"
    size="small"
    href="https://github.com/mp04499/Game-Programming-FPS-Project"
    target="_blank">
    Source
  </Button>
);

class FPS extends Component {

  render() {

    return (
      <div>
        <PageHeader
          onBack={() => this.goBack}
          title="First Person Shooter"
          subTitle="Unity & C#"
        />

        <MediaQuery query="(min-device-width: 1224px)">
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            style={{ width: '50%' }}
          >
            <Panel header="Description" key="1">
              {description}
            </Panel>
            <Panel header="Features" key="2">
              {features}
            </Panel>
            <Panel header="Code Snippet" key="3">
              {snippet}
            </Panel>
          </Collapse>
          <br />
          {source}
        </MediaQuery>

        <MediaQuery query="(max-device-width: 1224px)">
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            style={{ width: '100%' }}>
            <Panel header="Description" key="1"
            >
              {description}
            </Panel>
            <Panel header="Features" key="2">
              {features}
            </Panel>
            <Panel header="Code Snippet" key="3">
              {snippet}
            </Panel>
          </Collapse>
          <br />
          {source}
        </MediaQuery>
      </div>
    );
  }
}

export default FPS;

import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';

const {
  Sider,
} = Layout;
const SubMenu = Menu.SubMenu;

class Header extends Component {

  state = {
    current: '1',
  };

  handleClick = (e) => {
    console.log('click ', e);
    this.setState({
      current: e.key,
    });
  };

  render() {
    return (

      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => { console.log(broken); }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo" />
        <Menu
          onClick={this.handleClick}
          theme="light"
          mode="inline"
          selectedKeys={[this.state.current]}
        >

          <Menu.Item key="1">
            <Icon type="info" />
            <span className="nav-text">Home</span>
            <Link to={'/home'}/>
          </Menu.Item>

          <SubMenu
            key="sub1"
            title={<span><Icon type="experiment" /><span>Projects</span></span>}
          >
            <Menu.Item key="3">
              CMS (Ongoing)
              <Link to={'/projects/cms'}/>
            </Menu.Item>
            <Menu.Item key="4">
              Rent a Movie
              <Link to={'/projects/rentamovie'}/>
            </Menu.Item>
            <Menu.Item key="5">
              A* 8 Puzzle Solver
              <Link to={'/projects/astar'}/>
            </Menu.Item>
            <Menu.Item key="6">
              First Person Shooter
              <Link to={'/projects/fps'}/>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="7" disabled={true}>
            <Icon type="upload" />
            <span className="nav-text">Posts</span>
            <Link to={'/drafts'}/>
          </Menu.Item>
          <Menu.Item key="8" disabled={true}>
            <Icon type="user" />
            <span className="nav-text">Sign Up</span>
            <Link to={'/signup'}/>
          </Menu.Item>
        </Menu>
      </Sider>

    );
  }
}

export default Header;

import React, { Component } from 'react';
import { PageHeader, Collapse, List, Button } from 'antd';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import MediaQuery from 'react-responsive';

const Panel = Collapse.Panel;

const code = `const FeedList = ({ user }) => {
  const feed = useContext(FeedContext) || [];
  const dispatch = useContext(DispatchContext);

  useEffect(() => {
    let snapShot;

    const listen = async () => {
      if (!user || feed.length < 1) return;
      const query = await listener(user.uid);
      snapShot = query.onSnapshot(async (doc) => {
        const updatedFeed = [];
        doc.forEach((post) => updatedFeed.push({ ...post.data(), id: post.id }));

        const newPosts = updatedFeed.filter((post) => feed.every((p) => p.id !== post.id));

        if (newPosts.length > 0 && newPosts !== feed) dispatch({ type: 'ADD', posts: newPosts });
      });
    };

    listen();

    return () => {
      if (snapShot) snapShot();
    };
  }, [user, feed]);

  return (
    <TransitionGroup className="Feed">
      {feed.map((post) => (
        <CSSTransition
          key={post.id}
          timeout={500}
          classNames="Feed"
          mountOnEnter
        >
          <Feed
            key={post.id}
            name={user.displayName}
            at={user.displayName}
            text={post.message}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default memo(FeedList);

FeedList.propTypes = {
  user: PropTypes.objectOf(PropTypes.object).isRequired,
};`;

const description = (
  <p style={{ paddingLeft: 24 }}>
    This is a web application that is an ongoing project that I work on daily.
    The frontend is coded using the React.js framework, with the backend done
    serverless using Node.js. Lastly, the database is Google Clouds NoSQL choice
    Firestore. Hosted two different places, one using Google Cloud App Engine
    along with Cloud Build for continuous deployment building a docker image,
    pushing it to gcr, and using that image to deploy the new version and the
    other method is hosted using Now by Zeit for frontend and backend serverless
    solutions.
  </p>
);

const data = [
  'NoSQL',
  'Serverless',
  'React.js',
  'Node.js',
  'Live Updated Posts',
  'Hooks, Contexts, & Reducers in React',
];
const features = (
  <p style={{ paddingLeft: 24 }}>
    <List
      size="small"
      bordered
      dataSource={data}
      renderItem={item => <List.Item>{item}</List.Item>}
    />
  </p>
);

const snippet = (
  <SyntaxHighlighter language="javascript" style={okaidia}>
    {code}
  </SyntaxHighlighter>
);

const source = (
  <Button
    icon="download"
    size="small"
    href="https://github.com/mp04499/CMS-Client"
    target="_blank"
  >
    Source
  </Button>
);

class CMS extends Component {
  render() {
    return (
      <div>
        <PageHeader
          onBack={() => this.goBack}
          title="Content Management System"
          subTitle="Web Application with Serverless Backend"
        />

        <MediaQuery query="(min-device-width: 1224px)">
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            style={{ width: '50%' }}
          >
            <Panel header="Description" key="1">
              {description}
            </Panel>
            <Panel header="Features" key="2">
              {features}
            </Panel>
            <Panel header="Code Snippet" key="3">
              {snippet}
            </Panel>
          </Collapse>
          <br />
          {source}
        </MediaQuery>

        <MediaQuery query="(max-device-width: 1224px)">
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            style={{ width: '100%' }}
          >
            <Panel header="Description" key="1">
              {description}
            </Panel>
            <Panel header="Features" key="2">
              {features}
            </Panel>
            <Panel header="Code Snippet" key="3">
              {snippet}
            </Panel>
          </Collapse>
          <br />
          {source}
        </MediaQuery>
      </div>
    );
  }
}

export default CMS;

import Header from './Header';
import Foot from './Foot';
import React, { Component } from 'react';
import { Layout } from 'antd';

const {
  Content,
} = Layout;

class Template extends Component {
  render() {
    return (
      <Layout>
        <Header />
        <Layout>
          <Content style={{ margin: '24px 16px 0' }}>
            <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          {this.props.children}
            </div>
          </Content>
          <Foot />
        </Layout>
      </Layout>
    );
  }

}

export default Template;

import React, { Component } from 'react';
import 'antd/dist/antd.css';

import { Route, Switch } from 'react-router-dom';


import Home from '../Routes/Home';
import RentAMovie from '../Routes/Projects/RentAMovie';
import AStar from '../Routes/Projects/AStar';
import FPS from '../Routes/Projects/FPS';
import CMS from '../Routes/Projects/CMS';
import Template from './Template';

class App extends Component {

  render() {
    return (
      <Switch>
        <Template>
          <Route path={'/home'} component={Home}/>
          <Route exact path={'/'} component={Home}/>
          <Route path={'/projects/cms'} component={CMS}/>
          <Route path={'/projects/rentamovie'} component={RentAMovie}/>
          <Route path={'/projects/astar'} component={AStar}/>
          <Route path={'/projects/fps'} component={FPS}/>
          <Route path={'/drafts'} component={FPS}/>
          <Route path={'/signup'} component={FPS}/>
        </Template>
      </Switch>
    );
  }
}

export default App;

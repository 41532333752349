import React, { Component } from 'react';
import { PageHeader, Collapse, List, Button } from 'antd';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { ghcolors } from 'react-syntax-highlighter/dist/esm/styles/prism';
import MediaQuery from 'react-responsive';

const Panel = Collapse.Panel;

const code = 'public function add($movie, $id) {\n' +
  '        $storedItem = [\n' +
  '            \'qty\' => 0,\n' +
  '            \'price\' => $movie->Price,\n' +
  '            \'item\' => $movie,\n' +
  '            ];\n' +
  '        if($this->movies) {\n' +
  '            if(array_key_exists($id, $this->movies)) {\n' +
  '                $storedItem = $this->movies[$id];\n' +
  '            }\n' +
  '        }\n' +
  '        $storedItem[\'qty\']++;\n' +
  '        $storedItem[\'price\'] = $movie->Price * $storedItem[\'qty\'];\n' +
  '        $this->movies[$id] = $storedItem;\n' +
  '        $this->totalQty++;\n' +
  '        $this->totalPrice += $movie->Price;\n' +
  '    }';

const description = (
  <p style={{ paddingLeft: 24 }}>
    This Rent a Movie project was created with HTML and
    CSS on the frontend and PHP using the Laravel
    Framework coupled with MySQL on the backend.
    This particular project was hosted on AWS using EC2
    for the server, Ubuntu 16 for the OS and Nginx for
    the web server, and the RDS to host the database.
  </p>
);

const data = [
  'Login & Registration System',
  'Cart System & Checkout using Sessions',
  'Movie Listing and Details through ' +
  'Laravel Routing and pulling from the database',
  'Overall User Transactions in Detail',
];
const features = (
  <p style={{ paddingLeft: 24 }}>
    <List
      size="small"
      bordered
      dataSource={data}
      renderItem={item => (<List.Item>{item}</List.Item>)}
    />
  </p>
);

const snippet = (

  <SyntaxHighlighter language='php' style={ghcolors}>
    {code}
  </SyntaxHighlighter>

);

const source = (
  <Button
    icon="download"
    size="small"
    href="https://github.com/mp04499/Rent-a-Movie-Project"
    target="_blank">
    Source
  </Button>
);

class RentAMovie extends Component {

  render() {

    return (

      <div>
        <PageHeader
          onBack={() => this.goBack}
          title="Rent a Movie"
          subTitle="PHP Based"
        />

        <MediaQuery query="(min-device-width: 1224px)">
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            style={{ width: '50%' }}
          >
            <Panel header="Description" key="1">
              {description}
            </Panel>
            <Panel header="Features" key="2">
              {features}
            </Panel>
            <Panel header="Code Snippet" key="3">
              {snippet}
            </Panel>
          </Collapse>
          <br />
          {source}
        </MediaQuery>

        <MediaQuery query="(max-device-width: 1224px)">
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            style={{ width: '100%' }}
          >
            <Panel header="Description" key="1">
              {description}
            </Panel>
            <Panel header="Features" key="2">
              {features}
            </Panel>
            <Panel header="Code Snippet" key="3">
              {snippet}
            </Panel>
          </Collapse>
          <br />
          {source}
        </MediaQuery>
      </div>

    );
  }
}

export default RentAMovie;

import React, { Component } from 'react';
import { Card, Button, Icon } from 'antd';
import MediaQuery from 'react-responsive';

const tabList = [
  {
    key: 'tab1',
    tab: 'About Me',
  },
  {
    key: 'tab2',
    tab: 'Github',
  },
  {
    key: 'tab3',
    tab: 'Contact Me',
  },
];

const contentList = {
  tab1: (
    <p>
      Application Engineer, with a Google Cloud Associate Cloud Engineer
      Certification. My most widely used programming language is Javascript, but
      I use Java for backend purposes as well. Currently working on getting my
      Professional Cloud Developer in my spare time and my side project CMS that
      I have under projects. I' m a big fan of serverless, but I'm pretty
      knowledgeable when it comes to Kubernetes (as you have to be for your cert
      anyway), as I use that on the job.
    </p>
  ),
  tab2: (
    <div>
      <p>
        The projects shown on this website and others that aren't can all be
        found on my Github. Link below as well as on their respective pages for
        their specific link.
      </p>
      <br />
      <p>
        <Button
          href="https://github.com/mp04499/"
          target="_blank"
          icon="code"
          size="small"
        >
          Github
        </Button>
      </p>
    </div>
  ),
  tab3: (
    <p>
      <a
        href="mailto:michael.powell@michaelpowell.net"
        style={{ color: 'black' }}
      >
        <Icon type="mail" /> Email
      </a>
      <br />
      <a
        href="https://www.linkedin.com/in/michael-powell-68360b95/"
        style={{ color: 'black' }}
      >
        <Icon type="linkedin" /> LinkedIn
      </a>
    </p>
  ),
};

class Home extends Component {
  state = {
    key: 'tab1',
  };

  onTabChange = (key, type) => {
    console.log(key, type);
    this.setState({ [type]: key });
  };

  render() {
    return (
      <div>
        <MediaQuery query="(min-device-width: 1224px)">
          <Card
            style={{ width: '50%' }}
            title="About Me"
            tabList={tabList}
            activeTabKey={this.state.key}
            onTabChange={key => {
              this.onTabChange(key, 'key');
            }}
          >
            {contentList[this.state.key]}
          </Card>
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
          <Card
            style={{ width: '100%' }}
            title="About Me"
            tabList={tabList}
            activeTabKey={this.state.key}
            onTabChange={key => {
              this.onTabChange(key, 'key');
            }}
          >
            {contentList[this.state.key]}
          </Card>
        </MediaQuery>
      </div>
    );
  }
}

export default Home;
